body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primaryColor: #1e2834;
  --secondaryColor: #204067;
  --light-gray: #f5f5f5;
  --mainRed: #fc5c65;
  --mainGreen: #20bf6b;
  --fs-xs: 0.875rem;
  --fs-md: 1.3125rem;
  --fs-lg: ;
  --fs-xl: ;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  color: #1e2834;
  color: var(--primaryColor);
}
input {
  outline: none;
  border: none;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #1e2834;
  color: var(--primaryColor);
}

.logo {
  text-transform: uppercase;
  font-family: "montserrat light";
  font-size: 0.875rem;
  font-size: var(--fs-xs);
}
.container {
  width: 95%;
  margin: auto;
  /* background-color: crimson; */
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #d5d5d5;
  cursor: pointer;
  display: block;
  margin-top: 1%;
}
input[type="checkbox"]:after {
  font-family: "font awesome 5 free";
  font-weight: 600;
  content: "\f00c";
  font-size: 0.875rem;
  font-size: var(--fs-xs);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
}
input[type="checkbox"]:checked {
  background: #0672ff;
}
input[type="checkbox"]:checked::after {
  display: flex;
}

.preloader {
  background: linear-gradient(to right, #95d5ff50, #95d5ff50, #95d5ff50),
    url(/static/media/loader.e55a1721.gif) no-repeat center;
  background-size: 90%;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
}

.skeleton {
  -webkit-animation: skeleton-animation 1s infinite alternate;
          animation: skeleton-animation 1s infinite alternate;
}
@-webkit-keyframes skeleton-animation {
  0% {
    background: hsl(199, 35%, 81%);
  }
  100% {
    background: hsl(199, 14%, 95%);
  }
}
@keyframes skeleton-animation {
  0% {
    background: hsl(199, 35%, 81%);
  }
  100% {
    background: hsl(199, 14%, 95%);
  }
}

/* ====================================================================================== */
/* =========================styling for cart items======================================= */
/* ====================================================================================== */
.cartItemWrapper {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  height: 5.32rem;
  padding-block: 0.625rem;
  padding-right: 10px;
}
.cartImageWrapper {
  padding-inline: 0.625rem;
}
.cartImageWrapper img {
  width: 100%;
  height: 100%;
}
.cartFlexItem {
  flex-basis: 100%;
}
.cartDescription {
  flex-basis: 200%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.95rem;
  font-family: "quicksand";
  color: var(--secondaryColor);
}
.cartItemName,
.totalPrice {
  font-family: "quicksand", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--secondaryColor);
}
.cartControl {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: right;
}
.cartPrice {
  visibility: hidden;
}
.cartBtn {
  color: #a329ff;
  font-size: 0.95rem;
  cursor: pointer;
}
.cartSaveToLater {
  margin-left: 1rem;
}
.remove {
  display: none;
}

.cartCheckOutSection {
  min-width: 300px;
  max-width: 500px;
}
.cartCheckOutSection .checkout {
  border-radius: 0px;
  margin: 1rem 0;
  cursor: pointer;
}
.cartCheckOutSection .payDisable {
  background: #20bf6aa9;
  cursor: not-allowed;
}

/* ====================================================================================== */
/* ====================================================================================== */

@media (min-width: 35rem) {
  .cartItemWrapper {
    border: 1px solid #89ddf8;
    background: white;
    max-width: 46rem;
    height: 7.06rem;
  }
  .cartMobilePrice {
    display: none;
  }
  .cartPrice {
    visibility: visible;
  }
  .remove {
    display: block;
  }
  .cartBtnControl .remove {
    display: inline;
    white-space: nowrap;
  }
}

@media (min-width: 56.25rem) {
  .cartPageWrapper {
    display: flex;
    justify-content: space-around;
  }
  .cartPageWrapper + div {
    flex-basis: 100%;
  }
}

.product {
  width: 100%;
  border-bottom: 1.1rem;
  margin-top: 1rem;
}
.productImgWrapper {
  width: 100%;
  height: 12.1875rem;
  overflow: hidden;
}

.productImg {
  width: 100%;
}
.productDesc {
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
  font-size: var(--fs-xs);
}

.name {
  display: inline;
  margin-right: 0.4rem;
}
.price {
  display: inline;
  font-size: 0.75rem;
  color: var(--secondaryColor);
  font-style: italic;
}

.rating * {
  cursor: pointer;
}
.dropDownWrapper {
  position: relative;
}
.dropDown {
  background: #c7c7c7;
  padding: 0.5rem;
  border-radius: 6px 6px 0rem 0rem;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
}
.dropDownIndicator {
  border-left: 1px solid gray;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
}

.dropDownList {
  box-shadow: 2px 3px 6px 2px rgba(0, 0, 0, 0.178);
  padding: 0rem 0.5rem 0.3rem 0.5rem;
  position: absolute;
  top: 100%;
  width: 100%;
  background: white;
  z-index: 1;
  display: none;
  /* display: none; */
}
.dropDownItem {
  padding-block: 0.5rem;
  font-size: var(--fs-xs);
  border-bottom: 1px solid rgb(218, 218, 218);
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  cursor: pointer;
}

.toggleDropDown {
  display: block;
}

.popup {
  width: 80%;
  max-width: 550px;
  margin: auto;
  position: fixed;
  bottom: 0;
  background: rgba(51, 52, 53, 0.795);
  color: white;
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 6px 6px 0 0;
  padding: 0.5rem;
  border: 2px solid black;
  border-bottom: 0px solid white;
  display: none;
  /* visibility: hidden; */
}
.popup a {
  color: #63a3f7;
  text-decoration: underline;
}
.closePopup {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  color: black;
}
.closePopup * {
  pointer-events: none;
}
@media (min-width: 35rem) {
  .productGridWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}
@media (min-width: 50rem) {
  .productGridWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    grid-gap: 1rem;
  }
}

.productFlexWrapper {
  /* display: flex; */
  justify-content: space-between;
}

.flexItem {
  width: 100%;
}
.productPageImgWrapper {
  width: 100%;
  height: 100%;
}
.flexItem img {
  width: 100%;
}

.buyBtn {
  display: block;
  outline: none;
  margin: 1rem auto;
  border: none;
  color: white;
  font-family: "quicksand", sans-serif;
  font-size: var(--fs-sm);
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 6px;
  max-width: 30rem;
}
.addToCart {
  background: var(--mainRed);
}
.productPlaceOrder {
  background: var(--mainGreen);
}

.desktopTitle {
  display: none;
}

@media (min-width: 50rem) {
  .productFlexWrapper {
    display: flex;
  }
  .desktopTitle {
    display: block;
    text-align: center;
    margin: 1rem;
  }
}

.navList {
  margin: 0;
  padding: 0;
}
.nav {
  position: relative;
}
.navHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-block: 1rem 0.7rem;
  width: 100%;
}

.search {
  width: 60%;
  max-width: 22.5rem;
  height: 35px;
  position: relative;
  background: var(--light-gray);
  border-radius: 100px;
}

.searchInput {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2rem);
  height: 100%;
  padding-inline: 1rem;
  background: transparent;
  font-size: var(--fs-sm);
}
.searchIcon {
  outline: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  font-size: var(--fs-sm);
  padding: 0.4rem 0.8rem;
  color: var(--secondaryColor);
}

.burgerMenu {
  z-index: 100;
}
.menu {
  pointer-events: none;
  font-size: 1.5rem;
}
/* ====================================================================================== */
/* =====================styling search filter content=========================================== */
.searchFilter {
  position: absolute;
  width: 95%;
  max-width: 38rem;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: white;
  z-index: 3;
  border-radius: 6px;
  padding: 1rem;
  padding-top: 0;
  box-shadow: 2px 3px 6px #00264954;
  display: none;
}
/* .searchFilter::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primaryColor);
  z-index: 123123;
  width: 100%;
  height: 100%;
} */
.logo {
  color: white;
}
.searchLinks {
  padding-block: 1rem;
  border-bottom: 1px solid #a6d1f8;
  white-space: nowrap;
  overflow: hidden;
}

nav {
  width: 100%;
  background: var(--primaryColor);
  position: absolute;
  height: 100vh;
  top: 0;
  z-index: 10;
  color: white;
  text-align: center;
  font-family: "quicksand", sans-serif;
  padding-top: 4rem;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  transition: all 0.2s ease-in-out;
  -webkit-transform-origin: top left;
          transform-origin: top left;
}
.logoCaption {
  margin-block: 4rem;
  font-size: var(--fs-md);
}
nav .caption {
  margin: 1rem;
}
.navLink {
  margin-top: 1.2rem;
}
.navList a {
  color: white;
}

.logout {
  background: var(--secondaryColor);
  padding: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.showNav {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  /* display: block; */
}

/* ===================================================== */

.searchBackground {
  position: fixed;
  top: 3.9rem;
  left: 0;
  width: 100%;
  height: calc(100% - 3.9rem);
  background: #20406796;
  z-index: 2;
  display: none;
}

/* ====================================================================================== */
/* ========================styling for CAROUSEL ITEMS==================================== */
/* ====================================================================================== */

.caroselGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0.4rem;
  margin-block: 1rem;
  overflow: hidden;
}
.carouselWrapper .carosel {
  width: 100%;
  height: 100%;
  background: #002649a2;
  color: white;
  border-radius: 8px;
  overflow: hidden;
  font-size: var(--fs-xs);
  text-transform: capitalize;
  font-family: "quicksand", sans-serif;
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;
}
.carosel * {
  pointer-events: none;
}
.carosel p {
  color: white;
  z-index: 2;
  position: sticky;
  top: 70%;
  left: 10%;
}
.categoryImage {
  position: absolute;
  height: 100%;
  width: 100%;
}
.rec.rec-arrow {
  display: none;
}

.carouselWrapper {
  margin-top: 1.25rem;
  max-width: 42.75rem;
}
.viewAll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewAll p {
  position: static;
}
.new {
  width: 400px;
  height: 300px;
}

/* ====================================================================================== */
/* ====================================================================================== */
/* ====================================================================================== */
/* ====================================================================================== */
/* ====================================================================================== */
@media (min-width: 37.5rem) {
  nav {
    height: auto;
    position: static;
    padding: 1rem;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  .logoCaption {
    display: none;
  }
  .burgerMenu {
    display: none;
  }
  .navList {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
  }
  .logo {
    color: var(--primaryColor);
  }
  .navLink {
    margin-top: 0;
  }
  .logout {
    display: none;
  }
}
@media (min-width: 50rem) {
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: auto;
  }
  .navList a {
    color: var(--primaryColor);
  }
  nav {
    background: transparent;
    padding: 1.3rem 0rem;
    margin-left: 2rem;
  }

  .navLink a {
    color: var(--primaryColor);
  }
  .burgerMenu {
    display: none;
  }
}

.register {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 220;
}
.registerHeaderWrapper {
  color: white;
  background: linear-gradient(to right, #1e28348a, #1e28348a),
    url("https://source.unsplash.com/random");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  max-width: 27rem;
  margin: auto;
  position: relative;
}
.backBtn {
  width: 2.3rem;
  height: 2.3rem;
  background: rgba(255, 255, 255, 0.253);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--fs-md);
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
}
.backBtn * {
  pointer-events: none;
}
.signupHeader {
  text-align: center;
  padding: 2rem;
}
.signupHeader p {
  color: white;
  font-family: "quicksand", sans-serif;
  width: 70%;
  margin: 1rem auto;
  font-size: var(--fs-xs);
}
.changeRegisterBtn {
  display: flex;
  justify-content: space-between;
  font-family: "quicksand", sans-serif;
  margin: auto;
}
.changeRegisterBtn a {
  color: white;
  display: block;
  width: 100%;
}
.registerBtn {
  width: 100%;
  text-align: center;
  padding: 0.4rem 1rem;
  border-bottom: 3px solid rgba(238, 251, 255, 0.863);
  cursor: pointer;
}
.active {
  border-bottom: 3px solid #0672ffa8;
}

/* =========================================================== */
/* =========================================================== */
/* =========================================================== */

.formContainer {
  width: 90%;
  max-width: 27rem;
  margin: 2rem auto;
}
.formInputWrapper {
  margin: 1rem auto;
  height: 3rem;
  position: relative;
}
.formInputItem {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 0rem);
  padding: 0 1rem;
  color: var(--secondaryColor);
  height: 100%;
  background: #f0f5fe;
  font-family: "quicksand", sans-serif;
  font-size: 1rem;
  border-radius: 7px;
}
.inputStatus {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem 1rem;
  color: #0672ff;
  font-size: var(--fs-md);
  display: none;
}
.error {
  font-size: 0.8rem;
  margin-right: 0.3rem;
  display: none;
}
.eye {
  display: block;
  cursor: pointer;
}
.eye * {
  pointer-events: none;
}

.privacyPolicyWrapper {
  display: flex;
  align-content: center;
}
.privacyText {
  width: 80%;
  color: gray;
  font-size: var(--fs-xs);
  margin-left: 0.3rem;
}
.privacyText a {
  color: #0672ff;
  text-decoration: underline;
  padding: 0rem 0.3rem;
  display: inline-block;
}
.submit {
  background: #0672ff;
  color: white;
  font-family: "quicksand", sans-serif;
  height: 2.2rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 4px;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
.google {
  background: #ffffff;
  color: var(--primaryColor);
  border: 1.3px solid #0672ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.googleImg {
  height: 70%;
  margin: auto 0.3rem;
  margin-right: 1rem;
}
.alert * {
  color: var(--mainRed);
  display: block;
}
.alert input::-webkit-input-placeholder {
  color: var(--mainRed);
}
.alert input:-ms-input-placeholder {
  color: var(--mainRed);
}
.alert input::placeholder {
  color: var(--mainRed);
}
.alert .inputStatus {
  display: flex;
  align-items: center;
}
.registerTitleDesktop {
  display: none;
  text-align: center;
  font-family: "poppins", sans-serif;
  font-weight: 300;
  color: var(--primaryColor);
}

.changeToLogin {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: center;
  font-family: "quicksand", sans-serif;
  display: none;
}
.logLogin {
  margin: 0;
}
.log {
  color: #0672ff;
  text-decoration: underline;
  padding-left: 0.5rem;
}
.blog {
  display: block;
}
@media (min-width: 27rem) {
  .formContainer {
    border: 1px solid #0672ff;
    padding: 1rem;
  }
  .register {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .changeToLogin {
    display: block;
  }
  .formControl {
    min-width: 450px;
  }
  .registerHeaderWrapper {
    display: none;
  }
  .formInputWrapper .formInputItem,
  .submit {
    border-radius: 0;
  }
  .registerTitleDesktop {
    display: block;
  }
  .submit {
    height: 3rem;
  }
}

