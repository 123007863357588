.register {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 220;
}
.registerHeaderWrapper {
  color: white;
  background: linear-gradient(to right, #1e28348a, #1e28348a),
    url("https://source.unsplash.com/random");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  max-width: 27rem;
  margin: auto;
  position: relative;
}
.backBtn {
  width: 2.3rem;
  height: 2.3rem;
  background: rgba(255, 255, 255, 0.253);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--fs-md);
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
}
.backBtn * {
  pointer-events: none;
}
.signupHeader {
  text-align: center;
  padding: 2rem;
}
.signupHeader p {
  color: white;
  font-family: "quicksand", sans-serif;
  width: 70%;
  margin: 1rem auto;
  font-size: var(--fs-xs);
}
.changeRegisterBtn {
  display: flex;
  justify-content: space-between;
  font-family: "quicksand", sans-serif;
  margin: auto;
}
.changeRegisterBtn a {
  color: white;
  display: block;
  width: 100%;
}
.registerBtn {
  width: 100%;
  text-align: center;
  padding: 0.4rem 1rem;
  border-bottom: 3px solid rgba(238, 251, 255, 0.863);
  cursor: pointer;
}
.active {
  border-bottom: 3px solid #0672ffa8;
}

/* =========================================================== */
/* =========================================================== */
/* =========================================================== */

.formContainer {
  width: 90%;
  max-width: 27rem;
  margin: 2rem auto;
}
.formInputWrapper {
  margin: 1rem auto;
  height: 3rem;
  position: relative;
}
.formInputItem {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 0rem);
  padding: 0 1rem;
  color: var(--secondaryColor);
  height: 100%;
  background: #f0f5fe;
  font-family: "quicksand", sans-serif;
  font-size: 1rem;
  border-radius: 7px;
}
.inputStatus {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem 1rem;
  color: #0672ff;
  font-size: var(--fs-md);
  display: none;
}
.error {
  font-size: 0.8rem;
  margin-right: 0.3rem;
  display: none;
}
.eye {
  display: block;
  cursor: pointer;
}
.eye * {
  pointer-events: none;
}

.privacyPolicyWrapper {
  display: flex;
  align-content: center;
}
.privacyText {
  width: 80%;
  color: gray;
  font-size: var(--fs-xs);
  margin-left: 0.3rem;
}
.privacyText a {
  color: #0672ff;
  text-decoration: underline;
  padding: 0rem 0.3rem;
  display: inline-block;
}
.submit {
  background: #0672ff;
  color: white;
  font-family: "quicksand", sans-serif;
  height: 2.2rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 4px;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
.google {
  background: #ffffff;
  color: var(--primaryColor);
  border: 1.3px solid #0672ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.googleImg {
  height: 70%;
  margin: auto 0.3rem;
  margin-right: 1rem;
}
.alert * {
  color: var(--mainRed);
  display: block;
}
.alert input::placeholder {
  color: var(--mainRed);
}
.alert .inputStatus {
  display: flex;
  align-items: center;
}
.registerTitleDesktop {
  display: none;
  text-align: center;
  font-family: "poppins", sans-serif;
  font-weight: 300;
  color: var(--primaryColor);
}

.changeToLogin {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: center;
  font-family: "quicksand", sans-serif;
  display: none;
}
.logLogin {
  margin: 0;
}
.log {
  color: #0672ff;
  text-decoration: underline;
  padding-left: 0.5rem;
}
.blog {
  display: block;
}
@media (min-width: 27rem) {
  .formContainer {
    border: 1px solid #0672ff;
    padding: 1rem;
  }
  .register {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .changeToLogin {
    display: block;
  }
  .formControl {
    min-width: 450px;
  }
  .registerHeaderWrapper {
    display: none;
  }
  .formInputWrapper .formInputItem,
  .submit {
    border-radius: 0;
  }
  .registerTitleDesktop {
    display: block;
  }
  .submit {
    height: 3rem;
  }
}
