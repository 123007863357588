.productFlexWrapper {
  /* display: flex; */
  justify-content: space-between;
}

.flexItem {
  width: 100%;
}
.productPageImgWrapper {
  width: 100%;
  height: 100%;
}
.flexItem img {
  width: 100%;
}

.buyBtn {
  display: block;
  outline: none;
  margin: 1rem auto;
  border: none;
  color: white;
  font-family: "quicksand", sans-serif;
  font-size: var(--fs-sm);
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 6px;
  max-width: 30rem;
}
.addToCart {
  background: var(--mainRed);
}
.productPlaceOrder {
  background: var(--mainGreen);
}

.desktopTitle {
  display: none;
}

@media (min-width: 50rem) {
  .productFlexWrapper {
    display: flex;
  }
  .desktopTitle {
    display: block;
    text-align: center;
    margin: 1rem;
  }
}
